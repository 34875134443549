import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k210.jpg'
import image_product_mobile from '../../../images/product/k210_mobile.jpg'

import substratelabels from "../../../content/substratelabels.json"

class K210Page extends Component {

	render() {
		return (
		  <Layout mobileTitle={true}
		  		customClass='product-title'
		  		activePath='product'
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-210 Acrylic 2K Skimcoat">
		  	<ProductPage
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-210 Acrylic 2K Skimcoat"
		  		productID={"76"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"cipn",
		  					"name":substratelabels['cipn']
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K210Page
